<template>
    <div class="qr-scanner">
        <v-row justify="center">
            <v-col md="8">
                <h3 class="mx-4 my-2">Scan your QR code</h3>
                <div class="mx-4 my-2 yellow-frame">
                    
                    <qrcode-stream @decode="onDecode" :camera="camera">
                        <div v-if="isDecoding" class="validation-pending">
                            <div class="text-center">
                                <v-progress-circular
                                    indeterminate
                                    size="64"
                                    color="primary"
                                ></v-progress-circular>

                            </div>

                        </div>
                    </qrcode-stream>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import Mixins from "@utils/mixins";
import { mapGetters } from 'vuex';

export default{
    name: 'BooksQRScanner',
    mixins: [Mixins.essentials],
    components:{
        QrcodeStream,
    },
    data(){
        return{
            routeName: this.$route.meta.is_activity ? 'ActivitySheet' : 'Book',
            isDecoding: false,
            camera: 'auto',
            classroomCodeMap: {
                'NUR' : 'Nursery',
                'JR' : 'Jr. KG',
                'SR' : 'Sr. KG',
                'PG': 'Playgroup'
            }
        }
    },
    computed:{
        ...mapGetters(["allRooms","userSubjects"]),

        currentUserRooms(){
            if (this.currentUser.is_admin) return this.allRooms;
            else if (this.currentUser.is_faculty){
                var facultyRoomIds = this.userSubjects.map(subject => subject.room).filter(id => id != null);
                return this.allRooms.filter(room =>
                    facultyRoomIds.includes(room.id) || room.class_teacher.id == this.currentUser.id
                );
            }
            else return [this.currentUser.room];
        }
    },

    methods:{
        async onDecode(decodedString){
            try {
                this.isDecoding = true
                this.camera = "off"
                var qrObj;
                var resourceFound = false; 
                qrObj = JSON.parse(decodedString); 
                if (qrObj.activity){
                    var classroomCode = (qrObj.reference).split('-')[0];
                    var activityRoom = this.currentUserRooms.find(r => r.sections == this.classroomCodeMap[classroomCode]);
                    if (activityRoom){
                        resourceFound = true;
                        this.$router.replace({ name: 'AllActivitySheetTopics', params: { qrActivityRoom: activityRoom, searchTopic: qrObj.topic } });
                    }
                }
                else if (qrObj.subject) {
                    // check if subject found 
                    let url = this.endpoints.getSubjectByRefNo + qrObj.subject + "/";
                    let subject = await this.api.call(this.essentials, url);
                    if (subject) {
                        resourceFound = true;
                        this.$router.replace({ name: 'AllBookTopics', params: { subjectId: subject.id, subjectName: subject.name , searchTopic: qrObj.topic } })
                    }
                }
                if (!resourceFound) 
                    this.showSnackbar({
                        title: "Invalid QR Code. Please try again.",
                        type: "error",
                    });
            }
            catch(e){
                this.showSnackbar({
                    title: "Invalid QR Code. Please try again.",
                    type: "error",
                });
            }
            finally{
                this.isDecoding = false
                this.camera = "auto"
            }
        }
    },
    created(){
        
    }
}
</script>
<style scoped>
.yellow-frame{
    border: yellow 3px solid;
}
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .8);
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
</style>